import { render, staticRenderFns } from "./Section.vue?vue&type=template&id=d832219c&scoped=true&"
import script from "./Section.vue?vue&type=script&lang=ts&"
export * from "./Section.vue?vue&type=script&lang=ts&"
import style0 from "./Section.vue?vue&type=style&index=0&id=d832219c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d832219c",
  null
  
)

export default component.exports